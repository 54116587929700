import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../store/userSlice";

import userSvg from "../../assets/icons/user.svg";
import Button from "../dom/Button";
import { logout } from "../../services/auth";

export default function UserDropdown() {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setIsLoading] = useState(false);

  const disconnect = async () => {
    setIsLoading(true);
    try {
      await logout();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div id="user-dropdown">
      <div className="block">
        <img className="avatar" src={userSvg} alt="user icon" />
        <p className="email">{user?.wallet}</p>
        <Button
          className="mt-2"
          onClick={() => navigate("/account/campaigns")}
          text={String(t("settings.manage_account"))}
        />
      </div>
      <div className="block">
        <Button
          error
          small
          onClick={() => disconnect()}
          text={String(t("auth.logout"))}
        />
      </div>
    </div>
  );
}
