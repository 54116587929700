import * as ethers from "ethers";
import { setChain, setPendingTx } from "../store/appSlice";
import { getToken } from "./auth";
import store from "./redux";

declare var window: { ethereum: any };

export function initChain() {
  store.dispatch(setChain(String(process.env.REACT_APP_CHAIN)));
}

const ABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "Claim",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "creator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "goal",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "startAt",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "endAt",
        type: "uint256",
      },
    ],
    name: "Launch",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "caller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Pledge",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "caller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Refund",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "caller",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newCreator",
        type: "address",
      },
    ],
    name: "TransferCampaign",
    type: "event",
  },
  {
    inputs: [],
    name: "campaignMaxDuration",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
    constant: true,
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "campaigns",
    outputs: [
      {
        internalType: "address",
        name: "creator",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "goal",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "pledged",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "refunded",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "startAt",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "endAt",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "claimed",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
    constant: true,
  },
  {
    inputs: [],
    name: "claimFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
    constant: true,
  },
  {
    inputs: [],
    name: "claimLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
    constant: true,
  },
  {
    inputs: [],
    name: "contributionCount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
    constant: true,
  },
  {
    inputs: [],
    name: "count",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
    constant: true,
  },
  {
    inputs: [],
    name: "launchFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
    constant: true,
  },
  {
    inputs: [],
    name: "maxFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
    constant: true,
  },
  {
    inputs: [],
    name: "moneyCount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
    constant: true,
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
    constant: true,
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "pledgedAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
    constant: true,
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_goal",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_startAt",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_endAt",
        type: "uint256",
      },
    ],
    name: "launch",
    outputs: [],
    stateMutability: "payable",
    type: "function",
    payable: true,
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_id",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_newCreator",
        type: "address",
      },
    ],
    name: "transferCampaign",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_id",
        type: "uint256",
      },
    ],
    name: "pledge",
    outputs: [],
    stateMutability: "payable",
    type: "function",
    payable: true,
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_id",
        type: "uint256",
      },
    ],
    name: "claim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_id",
        type: "uint256",
      },
    ],
    name: "adminClaim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_id",
        type: "uint256",
      },
    ],
    name: "refund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_fee",
        type: "uint256",
      },
    ],
    name: "setFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const environment: any = {
  chains: {
    goerli: {
      chainName: "goerli",
      rpcUrls: [
        "https://goerli.infura.io/v3/",
        "https://endpoints.omniatech.io/v1/eth/goerli/public",
      ],
      blockExplorerUrls: ["https://goerli.etherscan.io/"],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      chain_id: 5,
      chain: "ETH",
    },
    ethereum: {
      chainName: "ethereum",
      rpcUrls: ["https://mainnet.infura.io/v3"],
      blockExplorerUrls: ["https://etherscan.io"],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      chain_id: 1,
      chain: "ETH",
    },
  },
};

export async function walletConnect(): Promise<string> {
  if (!window.ethereum) {
    alert("Metamask not installed");
    throw new Error("Metamask not installed");
  }
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  const { chainId } = await provider.getNetwork();
  await checkNetwork(chainId);
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();

  return signer.getAddress();
}

export async function getWalletConnected() {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner();
    return await signer.getAddress();
  } catch (error) {
    return undefined;
  }
}

export async function signMessage(message: string): Promise<string> {
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  const signer = provider.getSigner();
  const signature = await signer.signMessage(message);

  return signature;
}

async function checkNetwork(chain_id: number): Promise<boolean> {
  const chainKey = store.getState().app.chain;

  if (chain_id === environment.chains[chainKey].chain_id) return true;

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          chainId:
            "0x" + Number(environment.chains[chainKey].chain_id).toString(16),
        },
      ], // chainId must be in hexadecimal numbers
    });
    return true;
  } catch (error: any) {
    if (error.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: environment.chains[chainKey].chainName,
              chainId:
                "0x" +
                Number(environment.chains[chainKey].chain_id).toString(16),
              nativeCurrency: environment.chains[chainKey].nativeCurrency,
              rpcUrls: environment.chains[chainKey].rpcUrls,
              blockExplorerUrls: environment.chains[chainKey].blockExplorerUrls,
            },
          ],
        });
        return true;
      } catch (addError) {
        throw new Error(
          `Error adding ${environment.chains[chainKey].chainName} to Metamask`
        );
      }
    }
  }

  throw new Error(
    `Wrong Network (${chain_id}). Switch to ${environment.chains[chainKey].chainName} (${environment.chains[chainKey].chain_id})`
  );
}

function getContract() {
  let provider;
  let signer;

  if (getToken()) {
    provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    signer = provider.getSigner();
  } else {
    const providersUrl = (process.env.REACT_APP_JSON_RPC as string).split(",");

    // const provider1 = new ethers.providers.JsonRpcProvider(
    //   process.env.REACT_APP_JSON_RPC
    // );

    const providers = providersUrl.map((p) => {
      return { provider: new ethers.providers.JsonRpcProvider(p) };
    });

    provider = new ethers.providers.FallbackProvider(providers, 1);

    const wallet = ethers.Wallet.createRandom();
    signer = wallet.connect(provider);
  }

  return new ethers.Contract(
    String(process.env.REACT_APP_CONTRACT),
    ABI,
    signer
  );
}
export interface iSolidityCampaign {
  claimed: boolean;
  creator: string;
  endAt: Date;
  startAt: Date;
  goal: number;
  pledged: number;
}

export async function launchCampaignSolidity(
  _goal: number,
  _start: Date,
  _end: Date
) {
  const contract = getContract();

  const goal = (_goal * 10 ** 18).toString();
  const start = classicDateToSolidityDate(_start);
  const end = classicDateToSolidityDate(_end);

  const launchFee = Number(await contract["launchFee"]());

  const tx = await contract["launch"](goal, start, end, {
    value: launchFee,
  });
  store.dispatch(setPendingTx(tx.hash));
}

export async function claim(id: number) {
  const contract = getContract();

  const tx = await contract["claim"](id);
  store.dispatch(setPendingTx(tx.hash));
}

export async function refund(id: number) {
  const contract = getContract();

  const tx = await contract["refund"](id);
  store.dispatch(setPendingTx(tx.hash));
}

export async function getPledgedAmount(id: number, wallet: string) {
  const contract = getContract();

  const amount = await contract["pledgedAmount"](id, wallet);

  return amount / 10 ** 18;
}

export async function getClaimFeePercentage() {
  const contract = getContract();
  const amount = await contract["claimFee"]();
  return Number(amount) / 10000;
}

export async function getLaunchFee() {
  const contract = getContract();
  const amount = await contract["launchFee"]();
  return Number(amount) / 10 ** 18;
}

export async function getCampaignSolidityData(
  _id: number
): Promise<iSolidityCampaign> {
  const contract = getContract();

  const campaign = await contract["campaigns"](_id);

  return {
    claimed: Boolean(campaign.claimed),
    creator: String(campaign.creator).toLowerCase(),
    endAt: solidityDateToClassicDate(campaign.endAt),
    startAt: solidityDateToClassicDate(campaign.startAt),
    goal: Number(campaign.goal) / 10 ** 18,
    pledged: Number(campaign.pledged) / 10 ** 18,
  };
}

export async function pledge(_id: number, _amount: number) {
  const contract = getContract();

  const tx = await contract["pledge"](_id, {
    value: (_amount * 10 ** 18).toString(),
  });

  store.dispatch(setPendingTx(tx.hash));
}

export function classicDateToSolidityDate(date: Date | string) {
  return Math.ceil(new Date(date).getTime() / 1000);
}

export function solidityDateToClassicDate(date: any) {
  return new Date(Number(date) * 1000);
}

export async function getTransactionReceipt(txId: string): Promise<any> {
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  const receipt = await provider.getTransactionReceipt(txId);
  if (receipt && receipt.blockNumber) {
    return receipt;
  }
  return undefined;
}

export async function isTransacitonFinished(txId: string): Promise<boolean> {
  const tx = await getTransactionReceipt(txId);
  if (tx) return true;
  return false;
}
