import CookiesBanner from "./components/CookiesBanner/CookiesBanner";
import Footer from "./components/Footer/Footer";
import Modal from "./components/Modal/Modal";
import Nav from "./components/Nav/Nav";
import PendingTx from "./components/PendingTx/PendingTx";
import Router, { ScrollToTop } from "./Router";

function App() {
  return (
    <>
      <Nav />
      <Router />
      <Footer />
      <PendingTx />
      <Modal />
      <CookiesBanner />
      <ScrollToTop />
    </>
  );
}

export default App;
