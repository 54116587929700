import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate } from "react-router-dom";
import Button from "../dom/Button";
import AccountCampaigns from "./sub/AccountCampaigns";
import AccountPledge from "./sub/AccountPledge";

function AccountRouter() {
  return (
    <Routes>
      <Route path="campaigns" element={<AccountCampaigns />} />
      <Route path="pledge" element={<AccountPledge />} />
    </Routes>
  );
}

export default function Account() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <main>
      <div className="container">
        <div className="content">
          <div className="d-flex flex-col">
            <div className="mx-2 d-flex gap-2">
              <Button
                small
                text={t("account.campaigns")}
                onClick={() => navigate("campaigns")}
              />
              <Button
                small
                text={t("account.pledge")}
                onClick={() => navigate("pledge")}
              />
            </div>
            <br />
            <div className="w100 d-flex flex-col">
              <AccountRouter />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
