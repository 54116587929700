import axios from "axios";
import { setUser } from "../store/userSlice";
import { signMessage, walletConnect } from "./ethers";
import { LANG } from "./i18next";
import store from "./redux";

export const TOKEN_KEY = "b47n587m693645ccwre6";

export interface iUser {
  _id: string;

  isAdmin: boolean;

  wallet: string;
  username: string;

  lang: LANG;

  token: string;
}

export async function getLoginToken(wallet: string): Promise<iUser> {
  const response = await axios.post("/token/get", { wallet });
  return response.data;
}

export async function enableLoginToken(
  wallet: string,
  token: string,
  signature: string
): Promise<iUser> {
  const response = await axios.post("/token/enable", {
    wallet,
    token,
    signature,
  });

  saveUserToLocal(response.data);

  return response.data;
}

function saveUserToLocal(user: iUser) {
  if (user.token) window.localStorage.setItem(TOKEN_KEY, user.token);
  store.dispatch(setUser(user));
  axios.defaults.headers.common["Authorization"] = "Bearer " + getToken();
}

export async function refreshUserData() {
  const token = getToken();

  if (!token) throw new Error("Token not found, log in first");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const response = await axios.get("auth");
  saveUserToLocal(response.data);
}

export async function logout() {
  window.localStorage.removeItem(TOKEN_KEY);
  store.dispatch(setUser(undefined));
}

export function getToken() {
  return window.localStorage.getItem(TOKEN_KEY);
}

export async function login() {
  const wallet = await walletConnect();
  const { token } = await getLoginToken(wallet);
  const signature = await signMessage(token);

  await enableLoginToken(wallet, token, signature);
}