interface Props {
  percentage: number;
  animated?: boolean;
}

export default function ProgressBar({ percentage, animated }: Props) {
  return (
    <div className={`linear-progress-bar ${animated ? "animated" : ""}`}>
      <div
        style={{ width: `${percentage}%` }}
        className="linear-progress"
      ></div>
    </div>
  );
}
