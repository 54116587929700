export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="content">
          <div className="text-center py-4">
            {process.env.REACT_APP_SEO_TITLE} © - {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </footer>
  );
}
