import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { iCampaign } from "../../services/campaign";
import {
  getCampaignSolidityData,
  iSolidityCampaign,
} from "../../services/ethers";
import { CircularProgressbar } from "react-circular-progressbar";
import { datetimeToString } from "../../utils/generic";
const sanitizeHtml = require("sanitize-html");

const DEFAULT_IMAGE =
  "https://res.cloudinary.com/dfeennjfg/image/upload/v1675079809/qiqimpcd6ahol9jfoeof.png";

interface Props {
  campaign: iCampaign;
}

export default function CampaignCard({ campaign }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [solidityCampaign, setSolidityCampaign] = useState<iSolidityCampaign>();

  useEffect(() => {
    (async () => {
      if (campaign.solidityId) {
        const solidityData = await getCampaignSolidityData(campaign.solidityId);
        setSolidityCampaign(solidityData);
      }
    })();

    // eslint-disable-next-line
  }, []);

  const progress = solidityCampaign
    ? (solidityCampaign.pledged / solidityCampaign.goal) * 100
    : 0;

  const isLive =
    campaign.startAt &&
    campaign.endAt &&
    new Date(campaign.startAt) < new Date() &&
    new Date(campaign.endAt) > new Date();

  const isFuture = campaign.startAt && new Date(campaign.startAt) > new Date();

  const isPast = campaign.endAt && new Date(campaign.endAt) < new Date();

  return (
    <div
      className="campaign-card"
      onClick={() => {
        const id = campaign.solidityId ?? campaign._id;
        navigate("/campaign/" + id);
      }}
    >
      <div
        style={{
          background: `url(${campaign.images?.[0] ?? DEFAULT_IMAGE})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
        className="img-container"
      ></div>
      <div className="hover">
        <div className="d-flex flex-col">
          <span className="area">{campaign.area}</span>
          <span className="company">{campaign.company}</span>
          <div className="description">
            <p>{sanitizeHtml(campaign.description, { allowedTags: [] })}</p>
          </div>
        </div>
        <div className="progress">
          <div className="progress-bar">
            <CircularProgressbar value={Math.floor(progress)} text={`${Math.floor(progress)}%`} />
          </div>
          <span className="progress-text">
            {solidityCampaign?.pledged.toFixed(3) ?? 0} /{" "}
            {solidityCampaign?.goal.toFixed(3) ?? 0} ETH
          </span>
        </div>
      </div>
      <div className="meta">
        <span className="title">{campaign.title}</span>
        {campaign.solidityId ? (
          <>
            {isLive ? <span className="live">{t("campaign.live")}</span> : null}
            {isFuture ? (
              <span className="future">
                {t("campaign.future")} {datetimeToString(campaign.startAt)}
              </span>
            ) : null}
            {isPast ? <span className="">{t("campaign.past")}</span> : null}
          </>
        ) : (
          <>
            <span className="pay">{t("campaign.pay")}</span>
          </>
        )}
      </div>
    </div>
  );
}
