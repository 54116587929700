import { RootState } from "./../services/redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppState {
  chain: string;
  query: any;
  pendingTx: string | undefined;
  modal: React.ReactNode | undefined;
}

const initialState: AppState = {
  chain: "",
  query: undefined,
  pendingTx: undefined,
  modal: undefined,
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setChain: (state, action: PayloadAction<string>) => {
      state.chain = action.payload;
    },
    setQuery: (state, action: PayloadAction<any>) => {
      state.query = action.payload;
    },
    setPendingTx: (state, action: PayloadAction<string | undefined>) => {
      state.pendingTx = action.payload;
    },
    setModal: (state, action: PayloadAction<React.ReactNode | null>) => {
      state.modal = action.payload;
    },
  },
});

export const { setChain, setQuery, setPendingTx, setModal } = appSlice.actions;

export const selectChain = (state: RootState) => state.app.chain;
export const selectQuery = (state: RootState) => state.app.query;
export const selectModal = (state: RootState) => state.app.modal;
export const selectPendingTx = (state: RootState) => state.app.pendingTx;

export default appSlice.reducer;
