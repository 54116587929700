import Logo from "../Logo/Logo";
import NavActions from "./sub/NavActions";
import NavSearch from "./sub/NavSearch";

export default function Nav() {
  return (
    <nav id="nav">
      <div className="container">
        <div className="content">
          <div className="only-desktop d-flex flex-center flex-between p-2">
            <Logo />
            <div className="w100 mx-2">
              <NavSearch />
            </div>
            <NavActions />
          </div>
          <div className="only-mobile d-flex flex-col flex-between">
            <div className="d-flex flex-center flex-between px-2">
              <Logo />
              <NavActions />
            </div>
            <div className="w100 px-3">
              <NavSearch />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
