import { useNavigate } from "react-router-dom";
import logoSvg from "../../assets/logo.svg";

export default function Logo() {
  const navigate = useNavigate();

  return (
    <div className="logo" onClick={() => navigate("/")}>
      <img src={logoSvg} alt={process.env.REACT_APP_SEO_TITLE + " logo"} />
    </div>
  );
}
