import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/dom/Button";
import Loader from "../../components/dom/Loader";
import Select from "../../components/dom/Select";
import {
  CAMPAIGN_CETEGORIES,
  editCampaign,
  getCampaign,
  iCampaign,
} from "../../services/campaign";
import {
  getClaimFeePercentage,
  getLaunchFee,
  launchCampaignSolidity,
} from "../../services/ethers";
import { compressImage, datetimeToString } from "../../utils/generic";
import Datetime from "react-datetime";
import ReactQuill from "react-quill";

const DEFAULT_QUILL = `<h1>A Beautiful Project</h1><h3>Made with ❤️ by ${process.env.REACT_APP_SEO_TITLE}</h3><p><br></p><p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo aperiam temporibus, voluptas maxime dolores saepe quod. Eum animi nam velit nesciunt beatae sint incidunt deleniti temporibus, quae, omnis nihil ratione, perferendis officia error rem nemo saepe eius non. Quidem, et.</p><p><br></p><ul><li>Lorem Ipsum</li><li>Dolor Sit</li></ul><p><br></p><p><em>Good luck! 💸</em></p>`;

export default function CreateCampaign() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();

  const [payload, setPayload] = useState<iCampaign>({});
  const [isLoading, setIsLoading] = useState(false);
  const [claimPercentage, setClaimPercentage] = useState(0);
  const [launchFee, setlaunchFee] = useState(0);

  const createCampaignSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await editCampaign(payload);
      if (payload.solidityId) navigate("/campaign/" + data._id);
      else navigate("/campaign/" + data._id + "/edit");
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const launchCampaignSoliditySubmit = async (
    e: FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!payload.goal || !payload.startAt || !payload.endAt)
        throw new Error("goal, start, end are required");

      await launchCampaignSolidity(
        Number(payload.goal),
        new Date(payload.startAt),
        new Date(payload.endAt)
      );
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const p = await getClaimFeePercentage();
        setClaimPercentage(p);
      } catch (error) {
        console.log(error);
      }
    })();

    (async () => {
      try {
        const fee = await getLaunchFee();
        setlaunchFee(fee);
      } catch (error) {
        console.log(error);
      }
    })();

    if (!id) return setPayload({});

    if (id === "1") return navigate("/campaign/tri3ute/edit")
    if ((id === "tri3ute")) id = "1";

    (async () => {
      setIsLoading(true);
      try {
        const data = await getCampaign(id);
        setPayload(data);
      } catch (error) {
        console.log(error);
        navigate("/campaign/edit");
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line
  }, [id]);

  const imagesUploadInput = (e: any) => {
    const images = Array.from(e.target.files);

    images.forEach(async (image: any) => {
      const readAsync = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onloadend = async () => {
          if (reader.result) {
            const compressedImage = await compressImage(String(reader.result));
            resolve(compressedImage);
          }
        };
      });

      setPayload((payload) => {
        if (!payload.images) payload.images = [];
        if (payload.images!.length >= 10) return { ...payload };
        payload.images!.push(readAsync as string);
        return { ...payload };
      });
    });
  };

  const descriptionImagesUploadInput = (e: any) => {
    const images = Array.from(e.target.files);

    images.forEach(async (image: any) => {
      const readAsync = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onloadend = async () => {
          if (reader.result) {
            const compressedImage = await compressImage(String(reader.result));
            resolve(compressedImage);
          }
        };
      });

      setPayload((payload) => {
        if (!payload.descriptionImages) payload.descriptionImages = [];
        if (payload.descriptionImages!.length >= 40) return { ...payload };
        payload.descriptionImages!.push(readAsync as string);
        return { ...payload };
      });
    });
  };

  const isLaunched = payload.solidityId ? true : false;
  const finished = new Date(String(payload?.endAt)) < new Date();

  if (isLoading) return <Loader />;

  return (
    <main id="create-page">
      <div className="container">
        <div className="content">
          {payload._id || payload.solidityId ? (
            <Button
              small
              text={t("campaign.view")}
              onClick={() =>
                navigate("/campaign/" + (payload.solidityId ?? payload._id))
              }
            />
          ) : null}
          <div className="d-flex responsive my-5 head">
            <div className="w100 left p-2">
              <span className="title">{t("create.title")}</span>
              <p className="text my-1">{t("create.text_1")}</p>
              <p className="text my-1">{t("create.text_2")}</p>
              <p className="text mt-5">
                {t("campaign.policy_all_or_nothing")}{" "}
                {datetimeToString(payload.endAt)}
              </p>
            </div>
            <div className="w100 right">
              <fieldset disabled={isLaunched}>
                <form
                  className="p-2 m-2"
                  onSubmit={launchCampaignSoliditySubmit}
                >
                  <span className="subtitle">{t("create.blockchain")}</span>
                  <div className="d-flex flex-col">
                    <label htmlFor="">{t("create.goal")}</label>
                    <input
                      min={0}
                      defaultValue={0}
                      required
                      type="number"
                      onClick={(e) => {
                        (e.target as any).select();
                      }}
                      step={"0.00001"}
                      value={payload.goal}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          goal: Number(e.target.value),
                        })
                      }
                    />
                  </div>

                  <div className="d-flex gap-2">
                    <div className="d-flex flex-col">
                      <label htmlFor="">{t("create.start_at")}</label>
                      <Datetime
                        value={payload.startAt}
                        onChange={(e) => {
                          if (isLaunched) return;
                          setPayload({
                            ...payload,
                            startAt: new Date(e.toLocaleString()),
                          });
                        }}
                      />
                    </div>
                    <div className="d-flex flex-col ">
                      <label htmlFor="">{t("create.end_at")}</label>
                      <Datetime
                        value={payload.endAt}
                        onChange={(e) => {
                          if (isLaunched) return;
                          setPayload({
                            ...payload,
                            endAt: new Date(e.toLocaleString()),
                          });
                        }}
                      />
                    </div>
                  </div>

                  {payload._id && !payload.solidityId ? (
                    <Button
                      className="w100 my-2"
                      text={t("campaign.launch")}
                      type={"submit"}
                      metadata={launchFee + " ETH"}
                    />
                  ) : null}

                  <hr />

                  <div className="mt-2">
                    <span>{t("create.claim_prediction")}</span>
                  </div>
                  <div className="prediction d-flex flex-col w100">
                    <div className="d-flex my-1 w100 flex-between">
                      <span>
                        {t("create.claim_fee")} (
                        {(claimPercentage * 100).toFixed(2)}%)
                      </span>
                      <span>
                        {((payload.goal ?? 0) * claimPercentage).toFixed(2)} ETH
                      </span>
                    </div>
                    <div className="d-flex my-1 w100 flex-between">
                      <span>{t("create.claim_total")}</span>
                      <span>
                        {(
                          (payload.goal ?? 0) -
                          (payload.goal ?? 0) * claimPercentage
                        ).toFixed(2)}{" "}
                        ETH
                      </span>
                    </div>
                  </div>
                </form>
              </fieldset>
            </div>
          </div>
          <fieldset disabled={finished}>
            <form onSubmit={createCampaignSubmit}>
              <div className="d-flex responsive my-5">
                <div className="left w100 p-2">
                  <span className="subtitle">{t("create.base_info")}</span>
                  <div className="d-flex flex-col">
                    <label htmlFor="">{t("create.area")}</label>
                    <Select
                      options={[
                        {
                          text: String(t("create.select_area")),
                          value: 0,
                          disabled: true,
                        },
                        ...Object.values(CAMPAIGN_CETEGORIES).map((value) => {
                          return {
                            // text: String(t("create.area_" + value)),
                            text: String(value),
                            value: value,
                          };
                        }),
                      ]}
                      defaultValue={0}
                      onChange={(e) => {
                        setPayload({ ...payload, area: e.target.value });
                      }}
                      value={payload.area}
                    />
                  </div>
                  <div className="d-flex flex-col">
                    <label htmlFor="">{t("create.campaign_title")}</label>
                    <input
                      onChange={(e) =>
                        setPayload({ ...payload, title: e.target.value })
                      }
                      value={payload.title}
                      type="text"
                      required
                    />
                  </div>
                  <div className="d-flex flex-col">
                    <label htmlFor="">{t("create.tags")}</label>
                    <div className="">
                      <Button
                        small
                        light
                        className="mt-1"
                        text={t("create.add_tag")}
                        onClick={() => {
                          if (!payload.tags) payload.tags = [];
                          payload.tags.push("");
                          setPayload({ ...payload });
                        }}
                      />
                    </div>
                    {payload.tags?.map((_, key) => {
                      return (
                        <div
                          className="my-1 d-flex gap-2 align-center"
                          key={"tag_" + key}
                        >
                          <div>
                            <Button
                              small
                              error
                              text={t("X")}
                              onClick={() => {
                                payload.tags!.splice(key, 1);
                                setPayload({
                                  ...payload,
                                });
                              }}
                            />
                          </div>
                          <input
                            type="text"
                            className="m-0"
                            value={payload.tags![key]}
                            onChange={(e) => {
                              payload.tags![key] = e.target.value;
                              setPayload({ ...payload });
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <br />
                  <br />
                  <span className="subtitle">{t("create.company_info")}</span>
                  <div className="d-flex flex-col">
                    <label htmlFor="">{t("create.company_name")}</label>
                    <input
                      onChange={(e) =>
                        setPayload({ ...payload, company: e.target.value })
                      }
                      value={payload.company}
                      type="text"
                      required
                    />
                  </div>
                  <div className="d-flex flex-col">
                    <label htmlFor="">{t("create.website")}</label>
                    <input
                      onChange={(e) =>
                        setPayload({ ...payload, website: e.target.value })
                      }
                      value={payload.website}
                      type="text"
                    />
                  </div>
                  <br />
                  <br />
                  <span className="subtitle">{t("create.tiers")}</span>
                  <div className="d-flex flex-col">
                    <Button
                      light
                      metadata={
                        t("create.tires_created") +
                        (payload.tiers?.length ?? "0")
                      }
                      className="mt-1"
                      text={t("create.add_tier")}
                      onClick={() => {
                        if (!payload.tiers) payload.tiers = [];
                        payload.tiers.push({});
                        setPayload({ ...payload });
                      }}
                    />

                    {payload.tiers?.map((_, key) => {
                      return (
                        <div className="tier p-2 my-2" key={"tier_" + key}>
                          <span className="subtitle">#{key + 1}</span>
                          <div>
                            <div className="d-flex flex-col">
                              <label htmlFor="">{t("create.tier_name")}*</label>
                              <input
                                type="text"
                                value={payload.tiers![key].name}
                                onChange={(e) => {
                                  payload.tiers![key].name = e.target.value;
                                  setPayload({ ...payload });
                                }}
                              />
                            </div>
                            <div className="d-flex flex-col">
                              <label htmlFor="">
                                {t("create.tier_threshold")}*
                              </label>
                              <input
                                type="number"
                                onClick={(e) => {
                                  (e.target as any).select();
                                }}
                                defaultValue={0}
                                min={0}
                                step={"0.00001"}
                                value={payload.tiers![key].threshold}
                                onChange={(e) => {
                                  payload.tiers![key].threshold = Number(
                                    e.target.value
                                  );
                                  setPayload({ ...payload });
                                }}
                              />
                              <label htmlFor="">
                                {t("create.tier_description")}
                              </label>
                            </div>
                            <div className="d-flex flex-col">
                              <textarea
                                value={payload.tiers![key].description}
                                onChange={(e) => {
                                  payload.tiers![key].description =
                                    e.target.value;
                                  setPayload({ ...payload });
                                }}
                                cols={30}
                                rows={3}
                              ></textarea>
                            </div>
                            <div className="d-flex flex-col">
                              <label htmlFor="">{t("create.tier_spots")}</label>
                              <input
                                onClick={(e) => {
                                  (e.target as any).select();
                                }}
                                type="number"
                                defaultValue={0}
                                min={0}
                                step={1}
                                value={payload.tiers![key].spots}
                                onChange={(e) => {
                                  payload.tiers![key].spots = Number(
                                    e.target.value
                                  );
                                  setPayload({ ...payload });
                                }}
                              />
                            </div>
                            <div className="d-flex flex-col">
                              <label htmlFor="">
                                {t("create.tier_whitelist")}
                              </label>
                              <input
                                type="text"
                                placeholder={String(
                                  t("create.tier_whitelist_placeholder")
                                )}
                                value={payload.tiers![key].whitelist}
                                onChange={(e) => {
                                  payload.tiers![key].whitelist =
                                    e.target.value;
                                  setPayload({ ...payload });
                                }}
                              />
                            </div>
                          </div>
                          <Button
                            onClick={() => {
                              payload.tiers!.splice(key, 1);
                              setPayload({
                                ...payload,
                              });
                            }}
                            text={t("create.remove_tier")}
                            error
                            small
                          />
                        </div>
                      );
                    })}
                  </div>
                  <br />
                  <br />
                  <span className="subtitle">{t("create.images")}</span>
                  <div className="d-flex flex-col">
                    <input
                      onChange={imagesUploadInput}
                      type="file"
                      id="form-images"
                      accept="image/png, image/jpeg"
                      multiple
                      hidden
                    />
                    <Button
                      light
                      metadata={`${(payload.images ?? []).length}/10`}
                      text={String(t("create.upload_images"))}
                      onClick={() => {
                        document.getElementById("form-images")?.click();
                      }}
                    />

                    <div className="img-preview-container mt-2">
                      {payload.images?.map((_, key) => {
                        return (
                          <div
                            key={"img_" + Math.random().toString()}
                            className="img-preview m-2"
                          >
                            <input
                              readOnly
                              type="text"
                              hidden
                              value={payload.images![key]}
                              name="images[]"
                            />

                            <div
                              style={{
                                backgroundImage: `url(${payload.images![key]})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                              }}
                              className="image"
                            ></div>
                            <Button
                              error
                              small
                              className="remove-btn"
                              onClick={() => {
                                setPayload((payload) => {
                                  payload.images!.splice(key, 1);
                                  return { ...payload };
                                });
                              }}
                              text={"X"}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <br />
                  <div>
                    <span className="subtitle">
                      {t("create.description_images")}
                    </span>
                    <br />
                    <span className="text">
                      {t("create.description_images_text")}
                    </span>
                  </div>
                  <div className="d-flex flex-col">
                    <input
                      onChange={descriptionImagesUploadInput}
                      type="file"
                      id="form-images-description"
                      accept="image/png, image/jpeg"
                      multiple
                      hidden
                    />
                    <Button
                      light
                      metadata={`${
                        (payload.descriptionImages ?? []).length
                      }/40`}
                      text={String(t("create.upload_images"))}
                      onClick={() => {
                        document.getElementById("form-images-description")?.click();
                      }}
                    />

                    <div className="img-preview-container mt-2">
                      {payload.descriptionImages?.map((_, key) => {
                        return (
                          <div
                            key={"img_" + Math.random().toString()}
                            className="img-preview m-2"
                          >
                            <input
                              readOnly
                              type="text"
                              hidden
                              value={payload.descriptionImages![key]}
                              name="images[]"
                            />

                            <div
                              style={{
                                backgroundImage: `url(${
                                  payload.descriptionImages![key]
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                              }}
                              className="image"
                            ></div>
                            <Button
                              error
                              small
                              className="remove-btn"
                              onClick={() => {
                                setPayload((payload) => {
                                  payload.descriptionImages!.splice(key, 1);
                                  return { ...payload };
                                });
                              }}
                              text={"X"}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="right w100 p-2">
                  <div className="d-flex flex-col h100">
                    <label htmlFor="">{t("create.description")}</label>
                    <ReactQuill
                      theme="snow"
                      value={payload.description ?? DEFAULT_QUILL}
                      onChange={(e) =>
                        setPayload({ ...payload, description: e })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="p-2">
                <Button
                  className="w100 my-3"
                  text={t("create.save")}
                  type="submit"
                />
              </div>
            </form>
          </fieldset>
        </div>
      </div>
    </main>
  );
}
