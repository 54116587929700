import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../components/dom/Button";
import Loader from "../../components/dom/Loader";
import {
  exportPledges,
  getCampaign,
  iCampaign,
  iTier,
} from "../../services/campaign";
import {
  getCampaignSolidityData,
  iSolidityCampaign,
  pledge,
  getPledgedAmount,
  refund,
  claim,
  getWalletConnected,
  walletConnect,
} from "../../services/ethers";
import { selectUser } from "../../store/userSlice";
import { datetimeToString, formToObject } from "../../utils/generic";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import cleft from "../../assets/icons/cleft.svg";
import cright from "../../assets/icons/cright.svg";
import { closeModal, openModal } from "../../components/Modal/Modal";
import { t } from "i18next";
import { login } from "../../services/auth";

const UNDERSTAND_KEY = "245V635B476N58";

interface PledgeConfirmModalProps {
  amount: number;
  campaign: iCampaign;
  onSuccess?: Function;
}

function PledgeConfirmModal({ amount, campaign }: PledgeConfirmModalProps) {
  const [confirmText, setConfirmText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checkboxValue, setCheckoxValue] = useState(false);

  const pledgeConfirm = async () => {
    setIsLoading(true);
    try {
      await pledge(campaign.solidityId!, amount);
      closeModal();

      localStorage.setItem(UNDERSTAND_KEY, "true");
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  // const isUnderstandKey = window.localStorage.getItem(UNDERSTAND_KEY)
  //   ? true
  //   : false;

  const isUnderstandKey = true;

  return (
    <>
      <span className="title mb-2">{t("pledge.modal_title")}</span>
      <p className="text my-1 w100">{t("pledge.modal_text_1")}</p>
      <p className="text my-1 w100">{t("pledge.modal_text_2")}</p>
      <p className="text my-1 w100">{t("pledge.modal_text_3")}</p>
      {!isUnderstandKey ? (
        <>
          <span className="w100 mt-2">
            {t("pledge.modal_instructions")}{" "}
            <strong>{t("pledge.agree_text")}</strong>
          </span>
          <input
            onChange={(e) => setConfirmText(e.target.value)}
            value={confirmText}
            className="w100 my-1"
            type="text"
            placeholder="..."
          />
        </>
      ) : (
        <div className="d-flex gap-2 mt-3 mb-1 w100">
          <input
            checked={checkboxValue}
            onChange={(e) => setCheckoxValue(e.target.checked)}
            type="checkbox"
            name=""
            id="understand"
          />
          <label htmlFor="understand">
            <strong>{t("pledge.agree_text")}</strong>
          </label>
        </div>
      )}
      <Button
        loading={isLoading}
        onClick={() => pledgeConfirm()}
        className="w100"
        text={t("pledge.confirm")}
        disabled={
          confirmText.toLowerCase() !==
            String(t("pledge.agree_text")).toLowerCase() && !checkboxValue
        }
      />
      <p className="text-small my-2">
        {t("campaign.policy_all_or_nothing")}{" "}
        {datetimeToString(campaign.endAt, false, true)}
      </p>
    </>
  );
}

export default function Campaign() {
  const [campaign, setCampaign] = useState<iCampaign>();
  const [solidityCampaign, setSolidityCampaign] = useState<iSolidityCampaign>();
  const [pledgedAmount, setPledgedAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImg, setSelectedImg] = useState(0);
  const [pledgeInput, setPledgeInput] = useState(0);
  const { pathname } = useLocation();

  const { t } = useTranslation();

  let { id } = useParams();
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const setImagesInDescription = (campaign: iCampaign) => {
    if (!campaign.descriptionImages?.length) return campaign;
    if (!campaign.descriptionImages) return campaign;
    if (!campaign.description) return campaign;

    const imgRegex = /img_\w+/g;
    const imgMatches = campaign.description.match(imgRegex);

    imgMatches?.forEach((e) => {
      const index = Number(e.split("_")[1]);
      const dim = Number(e.split("_")[2]);
      const url = campaign.descriptionImages![index];

      if (!url) return;

      const img = `<img ${
        dim ? `style="width:${dim}px"` : ""
      } class="description-image" src="${url}" alt="${campaign.title}">`;
      campaign.description = campaign.description?.replace(e, img);
    });

    return campaign;
  };

  useEffect(() => {
    if (!id) return navigate("/");

    if (id === "1") return navigate("/campaign/tri3ute");
    if (id === "tri3ute") id = "1";

    (async () => {
      setIsLoading(true);
      try {
        let data = await getCampaign(id);
        data = setImagesInDescription(data);
        setCampaign(data);

        if (!data.solidityId && data.wallet !== user?.wallet)
          return navigate("/");

        if (pathname.includes(String(data._id)) && data.solidityId)
          return navigate("/campaign/" + data.solidityId);

        if (data.solidityId) {
          const solidityData = await getCampaignSolidityData(data.solidityId);

          setSolidityCampaign(solidityData);

          if (user?.wallet) {
            const amount = await getPledgedAmount(
              data.solidityId,
              user?.wallet
            );

            console.log(amount);
            setPledgedAmount(amount);
          }
        }
      } catch (error) {
        console.log(error);
        // navigate("/");
      }

      setIsLoading(false);
    })();

    // eslint-disable-next-line
  }, [id, user]);

  const pledgeSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!(await getWalletConnected())) {
      walletConnect();
      return;
    }

    const data = formToObject(e.target);
    if (data.amount > 0)
      openModal(
        <PledgeConfirmModal amount={data.amount} campaign={campaign!} />
      );
  };

  const refundClick = async () => {
    if (!(await getWalletConnected())) {
      walletConnect();
      return;
    }
    setIsLoading(true);
    try {
      if (campaign?.solidityId) await refund(campaign.solidityId);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const claimClick = async () => {
    if (!(await getWalletConnected())) {
      walletConnect();
      return;
    }
    setIsLoading(true);
    try {
      if (campaign?.solidityId) await claim(campaign.solidityId);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const isOwner = user?.wallet === campaign?.wallet;
  const isContributor = pledgedAmount > 0;
  const isLaunched = campaign?.solidityId ? true : false;
  const notStarted = new Date(String(solidityCampaign?.startAt)) > new Date();
  const finished = new Date(String(solidityCampaign?.endAt)) < new Date();
  const running =
    new Date(String(solidityCampaign?.startAt)) < new Date() &&
    new Date(String(solidityCampaign?.endAt)) > new Date();
  const isSuccess =
    solidityCampaign && solidityCampaign.pledged >= solidityCampaign.goal;
  const isClaimed = solidityCampaign?.claimed;

  const progress = solidityCampaign
    ? (solidityCampaign.pledged / solidityCampaign.goal) * 100
    : 0;

  const isWhitelisted = (tier: iTier) => {
    if (!user) return false;
    return tier.whitelist?.toLowerCase().includes(user.wallet.toLowerCase());
  };

  if (isLoading)
    return (
      <div className="h100 w100 d-flex flex-col align-items-center justify-content-center">
        <Loader />
        {!user ? (
          <p className="text-center mt-2">
            {t("loading.long")}{" "}
            <span
              onClick={async () => {
                await login();
                window.location.reload();
              }}
              className="underline cursor-pointer"
            >
              {t("loading.connect")}
            </span>
          </p>
        ) : null}
      </div>
    );

  return (
    <main id="campaign-page">
      <div className="container">
        <div className="content">
          <div className="d-flex gap-2 mx-2">
            {isOwner ? (
              <Button
                small
                onClick={() => navigate("edit")}
                text={t("campaign.edit")}
              />
            ) : null}
            {isLaunched && isOwner ? (
              <Button
                onClick={() => exportPledges(String(campaign?.solidityId))}
                small
                text={t("campaign.export")}
              />
            ) : null}
          </div>
          <div className="text-center my-5">
            <h1>{campaign?.title}</h1>
            <i>
              <span className="mx-1">{t("campaign.created_by")}</span>
            </i>
            {campaign?.website ? (
              <a
                rel="noreferrer"
                target={"_blank"}
                href={
                  campaign?.website?.startsWith("http://") ||
                  campaign?.website?.startsWith("https://")
                    ? campaign?.website
                    : "https://" + campaign?.website
                }
              >
                {campaign?.company}
              </a>
            ) : (
              <span>{campaign?.company}</span>
            )}
          </div>
          <div className="d-flex responsive">
            <div className="left d-flex flex-col p-2">
              <div className="img-load">
                <Loader />
              </div>
              <div
                style={{
                  backgroundImage: `url(${campaign?.images?.[selectedImg]})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
                className="img-container w100 h100 d-flex flex-center"
              >
                {campaign?.images?.length ? (
                  <div className="d-flex flex-between w100 p-1">
                    <Button
                      light
                      icon={cleft}
                      onClick={() => {
                        if (selectedImg === 0)
                          setSelectedImg(campaign.images!.length - 1);
                        else setSelectedImg(selectedImg - 1);
                      }}
                    />
                    <Button
                      light
                      icon={cright}
                      onClick={() => {
                        if (selectedImg === campaign.images!.length - 1)
                          setSelectedImg(0);
                        else setSelectedImg(selectedImg + 1);
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="right d-felx flex-col p-2">
              <div className="d-flex flex-col flex-center gap-1 mb-2">
                <ProgressBar
                  animated={running}
                  percentage={Math.floor(progress)}
                />
                <span>{!isNaN(progress) ? Math.floor(progress) : 0}%</span>
              </div>
              <div className="my-5">
                <div className="d-flex flex-col my-3">
                  <span className="title">
                    {solidityCampaign?.pledged.toFixed(3)} ETH
                  </span>
                  <span className="subtitle">
                    {t("campaign.on_amount")}{" "}
                    {solidityCampaign?.goal.toFixed(3)} ETH
                  </span>
                </div>
                <div className="d-flex flex-col my-3">
                  <span className="title">{campaign?.pledgesCount}</span>
                  <span className="subtitle">{t("campaign.contributors")}</span>
                </div>
                {running ? (
                  <div className="d-flex flex-col my-3">
                    <span className="title">
                      {datetimeToString(campaign?.endAt, false, true)}
                    </span>
                    <span className="subtitle">{t("campaign.ends_at")}</span>
                  </div>
                ) : null}
                {notStarted ? (
                  <div className="d-flex flex-col my-3">
                    <span className="title">
                      {datetimeToString(campaign?.startAt, false, true)}
                    </span>
                    <span className="subtitle">{t("campaign.starts_at")}</span>
                  </div>
                ) : null}
                {finished ? (
                  <div className="d-flex flex-col my-3">
                    <span className="title">{t("campaign.finished")}</span>
                  </div>
                ) : null}
              </div>
              <div className="my-3">
                {isContributor ? (
                  <span>
                    {t("campaign.contribute_for")}: {pledgedAmount.toFixed(3)}{" "}
                    ETH
                  </span>
                ) : null}
              </div>
              {running ? (
                <form
                  className="w100 my-3 d-flex flex-col gap-1"
                  onSubmit={pledgeSubmit}
                >
                  <label className="subtitle">{t("pledge.amount")}</label>
                  <input
                    value={pledgeInput}
                    onChange={(e) => setPledgeInput(Number(e.target.value))}
                    onClick={(e) => (e.target as any).select()}
                    required
                    name="amount"
                    type="number"
                    step={"0.000001"}
                    min={0}
                    className="w100"
                  />
                  <Button
                    className="w100"
                    type="submit"
                    text={t("pledge.submit")}
                  />
                  <span className="subtitle">
                    <strong>{t("campaign.policy_disclaimer")}</strong>
                  </span>
                </form>
              ) : null}
              <div className="my-3">
                {isOwner && finished && isSuccess && !isClaimed ? (
                  <Button
                    small
                    text={t("campaign.claim")}
                    onClick={() => claimClick()}
                  />
                ) : null}
              </div>
              <div className="my-3">
                {isContributor && finished && !isSuccess && !isClaimed ? (
                  <Button
                    small
                    text={t("pledge.refund")}
                    onClick={() => refundClick()}
                  />
                ) : null}
              </div>
              <span className="subtitle">
                {t("campaign.policy_all_or_nothing")}{" "}
                {datetimeToString(campaign?.endAt, false, true)}
              </span>
            </div>
          </div>
          <div className="tags px-2 mt-1">
            {campaign?.tags?.map((tag, key) => {
              return (
                <span className="mx-1" key={"tag_" + key}>
                  #{tag}
                </span>
              );
            })}
          </div>
          <div className="d-flex responsive mt-5">
            {campaign?.description ? (
              <div
                dangerouslySetInnerHTML={{ __html: campaign?.description }}
                className="left mobile-order-2 p-2 html"
              ></div>
            ) : null}
            <div className="right mobile-order-1 p-2">
              <div className="d-flex flex-col">
                <span className="title">{t("campaign.tiers")}</span>
              </div>
              {campaign?.tiers?.map((tier, key) => {
                return (
                  <div
                    onClick={() => {
                      if (finished) return;
                      if (tier.whitelist && !isWhitelisted(tier)) return;
                      if (
                        tier.usedSpots &&
                        tier.spots &&
                        tier.usedSpots >= tier.spots
                      )
                        return;

                      tier.threshold && setPledgeInput(tier.threshold);
                      window.scrollTo(0, 0);
                    }}
                    key={"tier_" + key}
                    className={`tier p-2 my-2 ${
                      (!tier.whitelist ||
                        (tier.whitelist && isWhitelisted(tier))) &&
                      (!tier.spots ||
                        (tier.spots &&
                          typeof tier.usedSpots === "number" &&
                          tier.usedSpots < tier.spots)) &&
                      !finished
                        ? "clickable"
                        : ""
                    }`}
                  >
                    <span className="title">
                      {tier.name} -{" "}
                      {Math.round(Number(tier.threshold) * 10 ** 3) / 10 ** 3}{" "}
                      ETH
                      <i>
                        {tier.whitelist
                          ? ` - ${t("campaign.tier_whitelist_required")}`
                          : ""}
                      </i>
                    </span>
                    <br />
                    {tier.whitelist ? (
                      <p>
                        {user ? (
                          <>
                            {isWhitelisted(tier)
                              ? t("campaign.tier_is_whitelisted")
                              : t("campaign.tier_is_not_whitelisted")}
                          </>
                        ) : (
                          <>{t("campaign.tier_whitelist_no_login")}</>
                        )}
                      </p>
                    ) : null}
                    {tier.spots && tier.spots > 0 ? (
                      <p>
                        {t("campaign.tier_spots")}{" "}
                        {(tier.usedSpots ?? 0) < tier.spots
                          ? tier.spots - (tier.usedSpots ?? 0)
                          : tier.spots}{" "}
                        / {tier.spots}
                      </p>
                    ) : null}
                    <p className="text mt-1">{tier.description}</p>
                  </div>
                );
              })}
              <hr />
              <div className="d-flex flex-col">
                <span className="subtitle">{t("campaign.tiers_text")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
