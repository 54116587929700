import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCampaingsPaginated, iCampaign } from "../../../services/campaign";
import { selectUser } from "../../../store/userSlice";
import CampaignList from "../../CampaignList/CampaignList";
import Button from "../../dom/Button";
import Loader from "../../dom/Loader";

export default function AccountCampaigns() {
  const [campaigns, setCampaigns] = useState<iCampaign[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  const loadCampaigns = async (page: number = 1) => {
    setIsLoading(true);
    try {
      const data = await getCampaingsPaginated(page, { wallet: user?.wallet });
      setCampaigns([...campaigns, ...data]);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadCampaigns(page);
    // eslint-disable-next-line
  }, [page, user]);

  if (isLoading) return <Loader />;

  return (
    <section>
      <CampaignList campaigns={campaigns} />
      {campaigns.length >= 25 ? (
        <div className="d-flex flex-center my-4">
          <Button
            onClick={() => setPage(page + 1)}
            loading={isLoading}
            text={t("search.load_more")}
            small
          />
        </div>
      ) : null}
    </section>
  );
}
