import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCampaingsPaginated, iCampaign } from "../../services/campaign";
import { selectQuery } from "../../store/appSlice";
import CampaignList from "../CampaignList/CampaignList";
import Button from "../dom/Button";

export default function Search() {
  const [campaigns, setCampaigns] = useState<iCampaign[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const query = useSelector(selectQuery);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (query?.$or) search(true);
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    if (query?.$or) search(false);
    // eslint-disable-next-line
  }, [page]);

  const search = async (refresh: boolean) => {
    setIsLoading(true);
    try {
      const data = await getCampaingsPaginated(page, query);
      if (refresh) setCampaigns(data);
      else setCampaigns([...campaigns, ...data]);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <main>
      <div className="container">
        <div className="content">
          {campaigns.length === 0 ? (
            <div className="text-center">{t("search.no_results")}</div>
          ) : null}
          <CampaignList campaigns={campaigns} />
          {campaigns.length >= 25 ? (
            <div className="d-flex flex-center my-4">
              <Button
                onClick={() => setPage(page + 1)}
                loading={isLoading}
                text={t("search.load_more")}
                small
              />
            </div>
          ) : null}
        </div>
      </div>
    </main>
  );
}
