import { t } from "i18next";
import { useEffect, useState } from "react";
import mapSvg from "../../assets/icons/map.svg";
import cashSvg from "../../assets/icons/cash.svg";
import giftSvg from "../../assets/icons/gift.svg";
import CampaignList from "../../components/CampaignList/CampaignList";
import Button from "../../components/dom/Button";
import { getCampaingsPaginated, iCampaign } from "../../services/campaign";

interface HowSlotInterface {
  image: any;
  title: string;
  text: string;
}

function HowSlot(props: HowSlotInterface) {
  return (
    <div className="slot w100 p-2">
      <img src={props.image} alt="step icon" />
      <span className="title">{props.title}</span>
      <span className="text">{props.text}</span>
    </div>
  );
}

export default function Home() {
  const [campaigns, setCampaigns] = useState<iCampaign[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const loadCampaigns = async () => {
    setIsLoading(true);
    try {
      const data = await getCampaingsPaginated(1, {
        solidityId: { $exists: true },
      });
      setCampaigns([...campaigns, ...data]);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadCampaigns();
    // eslint-disable-next-line
  }, [page]);

  return (
    <main id="home">
      <div className="container">
        <div className="content">
          <div className="hero w100 d-flex flex-center text-center mb-4 p-2">
            <hgroup>
              <h1>
                <span>{t("home.title_1")}</span>
                <span>{t("home.title_2")}</span>
              </h1>
              <p className="subtitle text-center p-2">
                <i>{t("home.subtitle")}</i>
              </p>
            </hgroup>
          </div>
          <div className="how w100 d-flex responsive flex-around gap-2 my-5">
            <HowSlot
              image={mapSvg}
              title={String(t("home.slot_title_1"))}
              text={String(t("home.slot_text_1"))}
            />
            <HowSlot
              image={cashSvg}
              title={String(t("home.slot_title_2"))}
              text={String(t("home.slot_text_2"))}
            />
            <HowSlot
              image={giftSvg}
              title={String(t("home.slot_title_3"))}
              text={String(t("home.slot_text_3"))}
            />
          </div>
          {/* <div className="my-5 text-center">
            <span className="list-title">{t("home.list_title")}</span>
          </div>
          <div className="w100 my-5">
            <CampaignList campaigns={campaigns} />
          </div>
          {campaigns.length >= 25 ? (
            <div className="d-flex flex-center my-4">
              <Button
                onClick={() => setPage(page + 1)}
                loading={isLoading}
                text={t("search.load_more")}
                small
              />
            </div>
          ) : null} */}
        </div>
      </div>
    </main>
  );
}
