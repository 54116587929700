import { useEffect, useState } from "react";

interface Props {
  options?: Array<{
    text: string;
    action?: Function;
    red?: boolean;
    hidden?: boolean;
  }>;
  icon?: any;
  className?: string;
  position?: "left" | "down" | "down-left";
  hovered?: boolean;
  small?: boolean;
  customContent?: JSX.Element;
}

export default function DropdownMenu(props: Props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsDropdownOpen(false);
    });
    window.addEventListener("click", () => {
      setIsDropdownOpen(false);
    });
  }, []);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
      }}
      className={`custom-dropdown-menu-container ${
        props.small ? "small" : ""
      } ${props.className} ${props.hovered ? "hovered" : ""}`}
    >
      <img className={`toggle-icon`} src={props.icon} alt="settings" />

      {isDropdownOpen ? (
        <div className="custom-dropdown-menu">
          <div className={`menu ${props.position ?? "down"}`}>
            {props.customContent
              ? props.customContent
              : props.options?.map((o, key) => {
                  return (
                    <div
                      onClick={() => {
                        o.action?.();
                      }}
                      key={"dropdown_" + key}
                      className={`menu-option ${o.red ? "red" : ""} ${
                        o.hidden ? "d-none" : ""
                      }`}
                    >
                      {o.text}
                    </div>
                  );
                })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
