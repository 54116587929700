import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./services/redux";
import { initi18next } from "./services/i18next";
import { getToken, refreshUserData } from "./services/auth";
import { initAxios } from "./services/axios";
import "./style/style.scss";
import { initChain, walletConnect } from "./services/ethers";
import { initializeAnalytics } from "./analytics";
if (process.env.REACT_APP_ROBOTS_POLICY === "all") initializeAnalytics();

(async () => {
  initChain();
  initi18next();
  initAxios();
  try {
    if (!getToken()) return;

    await walletConnect();
    await refreshUserData();
  } catch (error) {
    console.log(String(error));
  }
})();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
