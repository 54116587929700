import { FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../services/redux";
import { setQuery } from "../../../store/appSlice";
import { formToObject } from "../../../utils/generic";

import searchSvg from "../../../assets/icons/search.svg";
import { t } from "i18next";

export default function NavSearch() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate("/search");
    const q = formToObject(e.target).q;

    const _query = {
      solidityId: { $exists: true },
      $or: [
        { _id: q },
        { wallet: q },
        { company: q },
        { solidityId: q },
        { title: { $regex: `.*${q}.*`, $options: "i" } },
        { description: { $regex: `.*${q}.*`, $options: "i" } },
        { area: q },
        { tags: { $regex: `${q}`, $options: "i" } },
      ],
    };

    dispatch(setQuery(_query));
  };

  return (
    <form id="search-form" className="w100" onSubmit={handleSubmit}>
      <input
        placeholder={String(t("search.quote"))}
        className="w100"
        required
        name="q"
        type="text"
      />
      <img className="icon" src={searchSvg} alt="search icon" />
    </form>
  );
}
