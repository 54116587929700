import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Account from "./components/Account/Account";
import Search from "./components/Search/Search";
import Campaign from "./pages/Campaign/Campaign";
import CreateCampaign from "./pages/Campaign/CreateCampaign";
import Home from "./pages/Home/Home";

export default function Router() {
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="campaign/:id/edit" element={<CreateCampaign />} />
      <Route path="campaign/create" element={<CreateCampaign />} />

      <Route path="campaign/:id" element={<Campaign />} />

      <Route path="account/*" element={<Account />} />
      <Route path="search" element={<Search />} />
    </Routes>
  );
}

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    // eslint-disable-next-line
  }, [pathname]);
  return <></>;
}
