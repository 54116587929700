import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { startListeningEvents } from "../../services/campaign";
import { isTransacitonFinished } from "../../services/ethers";
import { useAppDispatch } from "../../services/redux";
import { selectPendingTx, setPendingTx } from "../../store/appSlice";
import Button from "../dom/Button";
import Loader from "../dom/Loader";

export default function PendingTx() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tx = useSelector(selectPendingTx);

  const checkTx = (tx: string) => {
    (async () => {
      try {
        await startListeningEvents();
      } catch (error) {
        console.log(error);
      }
    })();

    setInterval(async () => {
      if (await isTransacitonFinished(tx)) {
        // clearInterval(intervalId);
        // dispatch(setPendingTx(undefined));
        setTimeout(() => {
          window.location.reload();
        }, 15000);
      }
    }, 2500);
  };

  useEffect(() => {
    if (!tx) return;
    checkTx(tx);
  }, [tx]);

  if (!tx) return <></>;

  return (
    <div id="pending-tx">
      <div className="pending-tx-container">
        <div className="pending-tx-modal p-2 d-flex flex-col gap-2 flex-center">
          <span className="title text-center">{t("pending.title")}</span>
          <Loader />
          <p className="text text-center">{t("pending.text")}</p>
          <a
            target={"_blank"}
            rel="noreferrer"
            href={`${process.env.REACT_APP_EXPLORER}/tx/${tx}`}
          >
            {tx.substring(0, 20)}....
          </a>
          <Button
            text={t("pending.discard")}
            small
            onClick={() => dispatch(setPendingTx(undefined))}
          />
        </div>
      </div>
    </div>
  );
}
