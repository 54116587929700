import axios from "axios";

export enum CAMPAIGN_CETEGORIES {
  TECHNOLOGY = "TECHNOLOGY",
  ART = "ART",
  FILM_AND_VIDEO = "FILM_AND_VIDEO",
  MUSIC = "MUSIC",
  FOOD_AND_BEVERAGE = "FOOD_AND_BEVERAGE",
  FASHION_AND_BEAUTY = "FASHION_AND_BEAUTY",
  GAMES = "GAMES",
  PUBLISHING = "PUBLISHING",
  PHOTOGRAPHY = "PHOTOGRAPHY",
  DESIGN = "DESIGN",
  SPORTS_AND_OUTDOORS = "SPORTS_AND_OUTDOORS",
  TRANSPORTATION = "TRANSPORTATION",
  HEALTH_AND_FITNESS = "HEALTH_AND_FITNESS",
  HOME_AND_LIVING = "HOME_AND_LIVING",
  EDUCATION_AND_LEARNING = "EDUCATION_AND_LEARNING",
  ENVIRONMENT = "ENVIRONMENT",
  TRAVEL = "TRAVEL",
  COMMUNITY = "COMMUNITY",
  PERSONAL_GROWTH = "PERSONAL_GROWTH",
  PETS_AND_ANIMALS = "PETS_AND_ANIMALS",
  NFT_LAUNCH = "NFT_LAUNCH",
  TOKEN_LAUNCH = "TOKEN_LAUNCH",
  PROTOCOL_LAUNCH = "PROTOCOL_LAUNCH",
  DEFI = "DEFI",
  BLOCKCHAIN_INFRASTRUCTURE = "BLOCKCHAIN_INFRASTRUCTURE",
  CRYPTO_TRADING = "CRYPTO_TRADING",
  PRIVACY_AND_SECURITY = "PRIVACY_AND_SECURITY",
}

export interface iTier {
  name?: string;
  threshold?: number;
  spots?: number;
  description?: string;
  whitelist?: string;
  usedSpots?: number;
}

export interface iCampaign {
  _id?: string;

  wallet?: string;
  solidityId?: number;

  title?: string;
  description?: string;
  area?: string;
  tags?: string[];
  images?: string[];
  descriptionImages?: string[];

  website?: string;
  company?: string;

  tiers?: iTier[];

  goal?: number;
  startAt?: Date;
  endAt?: Date;

  pledgesCount?: number;
}

export interface iPledge {
  _id?: string;

  wallet?: string;
  amount?: number;
  campaignSolidityId?: number;

  _createdAt?: any;
}

export async function editCampaign(payload: any): Promise<iCampaign> {
  const response = await axios.post("campaign", payload);
  return response.data;
}

export async function getCampaign(id: string): Promise<iCampaign> {
  const response = await axios.get("campaign/" + id);
  return response.data;
}

export async function getCampaingsPaginated(
  page: number = 1,
  query?: object
): Promise<iCampaign[]> {
  let url = `campaign/paginate?page=${page}`;
  if (query) url += `&query=${JSON.stringify(query)}`;
  const response = await axios.get(url);
  return response.data;
}

export async function getPledgesPaginated(
  page: number = 1,
  id?: string,
  wallet?: string
): Promise<iPledge[]> {
  let url = "";

  if (wallet) {
    url = `contributions/paginate?wallet=${wallet}&page=${page}`;
  }

  if (id) {
    url = `campaign/${id}/contributions/paginate?page=${page}`;
  }

  if (!wallet && !id) return [];

  const response = await axios.get(url);
  return response.data;
}

export async function exportPledges(id: string) {
  window.location = (process.env.REACT_APP_BACKEND_URI +
    "/campaign/" +
    id +
    "/export") as any;
}

export async function startListeningEvents() {
  await axios.post("/event/listen");
}
