import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/userSlice";
import Button from "../../dom/Button";
import { useNavigate } from "react-router-dom";
import UserDropdown from "../../UserDropdown/UserDropdown";
import DropdownMenu from "../../DropdownMenu/DropdownMenu";

import userSvg from "../../../assets/icons/user.svg";
import { login } from "../../../services/auth";

export default function NavActions() {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const connect = async () => {
    setIsLoading(true);

    try {
      await login();
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  return (
    <div className="d-flex flex-center gap-2">
      {user ? (
        <>
          <DropdownMenu
            hovered
            position="down-left"
            customContent={<UserDropdown />}
            icon={userSvg}
          />
        </>
      ) : (
        <Button icon={userSvg} onClick={() => connect()} loading={isLoading} />
      )}
      <Button
        onClick={() => {
          user ? navigate("campaign/create") : connect();
        }}
        text={t("nav.create")}
      />
    </div>
  );
}
