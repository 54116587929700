import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPledgesPaginated, iPledge } from "../../../services/campaign";
import { selectUser } from "../../../store/userSlice";
import { datetimeToString } from "../../../utils/generic";
import Button from "../../dom/Button";
import Loader from "../../dom/Loader";

export default function AccountPledge() {
  const [pledges, setPledges] = useState<iPledge[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const loadPledges = async (page = 1) => {
    setIsLoading(true);
    try {
      const data = await getPledgesPaginated(page, undefined, user?.wallet);
      setPledges(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!user?.wallet) return;
    loadPledges(page);

    // eslint-disable-next-line
  }, [user, page]);

  if (isLoading) return <Loader />;

  return (
    <section>
      <table>
        <thead>
          <tr>
            <th>Campaign ID</th>
            <th>Amount</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {pledges.map((pledge, key) => (
            <tr key={"pledge_" + key}>
              <td>{pledge.campaignSolidityId}</td>
              <td>{pledge.amount} ETH</td>
              <td>{datetimeToString(pledge._createdAt, true, true)}</td>
              <td>
                <div className="d-flex flex-center">
                  <Button
                    small
                    text={t("campaign.view")}
                    onClick={() =>
                      navigate("/campaign/" + pledge.campaignSolidityId)
                    }
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {pledges.length >= 25 ? (
        <div className="d-flex flex-center my-4">
          <Button
            onClick={() => setPage(page + 1)}
            loading={isLoading}
            text={t("search.load_more")}
            small
          />
        </div>
      ) : null}
    </section>
  );
}
