import { iCampaign } from "../../services/campaign";
import CampaignCard from "./CampaignCard";

interface Props {
  campaigns: iCampaign[];
}

export default function CampaignList({ campaigns }: Props) {
  return (
    <div className="w100 campaign-list">
      {campaigns.map((c, key) => (
        <CampaignCard key={key} campaign={c} />
      ))}
    </div>
  );
}
